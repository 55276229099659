export const CONFIG = {
  ban: {
    description:
      "Did you notice a user acting bad after being Restricted in one community or you noticed behaviour that needs to be stopped at once? It is time to request a ban this user from all communities",
    title: "Reason to Ban this user",
    placeholder: "Additional Notes - Describe here in more detail why",
    icon: "cancel",
  },
  flag: {
    note: "A flag is network wide and visible to all Communities.",
    description:
      "Did you notice a user that was acting bad within the Community, whether it was on the ThriveCoin Network or other networks (Discord etc.)? Then flag this user in the system so its easier to follow in the future up if behaviour stays unchanged.",
    icon: "warning",
  },
  restrict: {
    note: "You can lift this restriction anytime if you wish this to be temporary. The community ban will stay in place if no action is taken.",
    description:
      "Did you notice a user acting bad after being flagged? The proper followup is to restrict a user from accessing the community, which prevents them from participating in seasons..",
    title: "Personalized Notification",
    icon: "hand",
  },
};

export const REASONS = [
  { id: "suspected_bot", label: "Suspected Bot" },
  { id: "confirmed_bot", label: "Confirmed Bot" },
  { id: "suspected_farming", label: "Suspected Farming" },
  { id: "confirmed_farming", label: "Confirmed Farming" },
  { id: "suspected_sybil", label: "Suspected Sybil" },
  { id: "confirmed_sybil", label: "Confirmed Sybil" },
  { id: "inappropriate_behavior", label: "Inappropriate Behavior" },
  { id: "other", label: "Other (and provide a place to describe)" },
];
