import UserImage from "@assets/user.svg";

export const THANK_YOU_NOTE_EXPLORER_URL = "https://thanks.note.thrivecoin.com";
export const PLATFORM_FEE = 2.32;
export const MINIMUN_CONTRIBUTION = 0.25;
export const THRIVE_DECIMALS = 8;
export const TRANSACTION_FEE = 0.008872994;
export const WALLET_CHAIN_TYPES = Object.freeze({
  ETHEREUM: "ethereum",
  VIRTUAL: "virtual",
  POLYGON: "polygon",
});
export const SKELETON_COUNT = 4;

export const STOCK_IMAGE = UserImage;
export const STOCK_IMAGE_MD = UserImage;
export const STOCK_IMAGE_SM = UserImage;

export const LISTING_STATUS_LABELS = {
  pending: "Pending creation",
  active: "Active",
  closed: "Closed",
  paused: "Paused",
};

// Order is important for color
export const VALID_SOCIAL_NETWORKS = [
  "discord",
  "twitter",
  "email",
  "asana",
  "linkedin",

  "bankless_discourse",
  "apecoin_discourse",
  "aavegotchi_discourse",
  "github",
  "gitcoin",
];

export const SOCIALS_NO_DISCORD = VALID_SOCIAL_NETWORKS.filter((name) => !name.includes("discourse"));

export const THRIVE_TWITTER_LINK = "https://twitter.com/thrivecoinhq";
export const THRIVE_DISCORD_LINK = "https://discord.gg/GNKfXNPKYc";
export const THRIVE_SUPPORT_EMAIL = "support@thrivecoin.com";
export const TOKEN_OPTIONS = [
  { value: "thrive", label: "ThriveCoin ($THRIVE)" },
  { value: "ape", label: "Apecoin ($APE)" },
  { value: "ywhales", label: "Ywhales ($YWHALES" },
  { value: "shapeshift", label: "Shapeshift ($FOX)" },
  { value: "talent", label: "Talent (TALENT)" },
  { value: "op", label: "OP (OP)" },
  { value: "ghost", label: "Ghost (GHST)" },
  { value: "arbitrum", label: "ARB (ARB)" },
  { value: "tarb", label: "tARB (tARB)" },
];
