import { Box, breakpoints, Button, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { DEFAULT_PROFILE_IMAGE } from "../../Constants";
import { CornerButton, StyledAvatar, TopWrapper } from "./common";

const EditPart = ({ showEdit, data }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const { updateProfileRequest, updateProfileImageRequest } = useStoreActions((actions) => actions.user);
  const { image_url, ...userData } = data;
  const { refresh } = useStoreActions((actions) => actions.authentication);
  const { showToast } = useStoreActions((actions) => actions.toasts);
  const [inputValue, setInputValue] = useState(userData);
  const [previewUrl, setPreviewUrl] = useState(image_url || DEFAULT_PROFILE_IMAGE);

  function handleChange(e) {
    const target = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  }

  const onFileChange = (event) => {
    const cover_media = event.target.files[0];
    const url = window.URL.createObjectURL(new Blob([cover_media]));

    updateProfileImageRequest(cover_media)
      .then(() => {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(url);
      })
      .catch(() => URL.revokeObjectURL(url));
  };

  const removeMedia = () => {
    updateProfileImageRequest("")
      .then(() => {
        setPreviewUrl(DEFAULT_PROFILE_IMAGE);
      })
      .catch((error) => {
        console.error("Failed to remove media:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateProfileRequest(inputValue)
      .then(refresh)
      .then((data) => {
        showEdit(false);
        showToast({
          content: "Your profile information has been successfully updated.",
          type: "success",
          dismissTime: 5000,
        });
      })
      .catch(({ response }) => {
        setError(response.data);
      })
      .finally(() => setLoading(false));
  };

  const handleEdit = () => {
    showEdit(false);
  };

  const errorText = (key) => {
    if (!!error && error.data?.errors?.[key]) {
      return error.data?.errors[key][0];
    }
    return "";
  };

  return (
    <TopWrapper description={true} isFromEdit={true}>
      <CornerButtonX onClick={handleEdit}>
        <IconSvg width="16px" color="01Primary700" icon="x" />
      </CornerButtonX>
      <ImageWrapper>
        <StyledAvatar src={previewUrl} alt="user" />

        <ButtonsWrapper flex gap="9px">
          <EditPhoto for="file-upload">
            <UploadIcon width="16px" icon="upload2" color="02Primary0" />
            <input onChange={onFileChange} id="file-upload" type="file" accept="image/*" />
          </EditPhoto>
          <Delete>
            <IconSvg onClick={removeMedia} width="16px" icon="trash-outline" color="02Primary0" />
          </Delete>
        </ButtonsWrapper>
      </ImageWrapper>

      <RightBox onSubmit={handleSubmit} onChange={handleChange}>
        <StyledInput
          invalid={!!errorText("username")}
          alwaysShowValidation={!!errorText("username")}
          validationText={errorText("username")}
          maxLength="25"
          name="username"
          value={inputValue.username}
          placeholder="username"
        />
        <StyledInput
          invalid={!!errorText("description")}
          alwaysShowValidation={!!errorText("description")}
          validationText={errorText("description")}
          name="description"
          value={inputValue.description}
          placeholder="Your Bio"
        />
        {!!errorText("description") && (
          <Box flex={+true} alignItems="center" gap="8px" color="01Primary700" spacing="mb-6">
            <IconSvg width="16px" icon="alert-triangle" />
            <ValidationText>{errorText("description")}</ValidationText>
          </Box>
        )}
        {!!error && !!error.message && !errorText("username") && !errorText("description") && (
          <Box flex={+true} alignItems="center" gap="8px" color="01Primary700">
            <IconSvg width="16px" icon="alert-triangle" />
            <ValidationText>{error.message}</ValidationText>
          </Box>
        )}
        <StyledButton borderRadius="40px" kind="solid" heightSize="56px" type="submit" loading={loading} widthFull>
          Update Info
        </StyledButton>
      </RightBox>
    </TopWrapper>
  );
};

const StyledInput = styled(Input)`
  margin-bottom: 20px;
  @media ${breakpoints.belowDesktop} {
    margin-bottom: 16px;
  }
`;

const StyledButton = styled(Button)`
  @media ${breakpoints.tablet} {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 16px;
    max-width: 522px;
  }
  @media ${breakpoints.mobile} {
    margin-top: 0;
  }
`;

const ButtonsWrapper = styled(Box)`
  position: absolute;
  bottom: 16px;
  left: 16px;

  ${IconSvg}{
    cursor: pointer;
  }
`;

const EditPhoto = styled.label`
  background: ${({ theme }) => theme.colors["02Primary500"]};
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors["02Primary0"]};

  svg {
    path {
      fill: white;
    }
  }

  input {
    display: none;
  }
`;

const ValidationText = styled(Text)`
  color: error;
  text-align: left;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
`;

const RightBox = styled.form`
  width: 100%;
  padding-right: 74px;
  @media ${breakpoints.tablet} {
    padding-right: 67px;
  }
  @media ${breakpoints.mobile} {
    padding-right: 16px;
  }
`;

const UploadIcon = styled(IconSvg)`
  path {
    fill: ${({ theme }) => theme.colors["02Primary0"]};
  }
`;

const Delete = styled.div`
  width: 42px;
  height: 42px;
  background-color: ${({ theme }) => theme.colors["02Primary500"]};
  border: 1px solid ${({ theme }) => theme.colors["02Primary0"]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const CornerButtonX = styled(CornerButton)`
  @media ${breakpoints.mobile} {
    top: 16px;
  }
`;

export default EditPart;
