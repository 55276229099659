import { Box, Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { StyledTextArea } from "./ReasonStep";

const NotifyStep = ({ title, description, setMessage }) => {
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <StyledBox>
      <Text typography="heading/small" spacing="mb-4" bold>
        {title}
      </Text>
      <Text typography="body/medium" spacing="mb-5">
        {description}
      </Text>

      <StyledTextArea
        bgColor="02PrimaryGrey50"
        color="02Primary500"
        placeholder="Type personal Message here..."
        rows="3"
        onChange={handleChange}
        modal
      ></StyledTextArea>
    </StyledBox>
  );
};

const Footer = ({ closeModal, btn, handleSubmit }) => {
  return (
    <Box flex gap="16px">
      <Button variant="white" kind="outline" borderRadius="50px" width="100%" onClick={closeModal} modal>
        Back
      </Button>
      <StyledButton borderRadius="50px" width="100%" leftIcon="check" onClick={handleSubmit} modal>
        {btn}
      </StyledButton>
    </Box>
  );
};

const StyledBox = styled(Box)`
  text-align: left;
`;

const StyledButton = styled(Button)`
  text-transform: capitalize;
`;

NotifyStep.Footer = Footer;

export default NotifyStep;
