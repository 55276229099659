import { useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import { deleteToken, readToken } from "./redux/httpRequester";

const useSession = () => {
  const [loading, setLoading] = useState(true);
  const { restoreSession, sessionChecked } = useStoreActions((actions) => actions.authentication);
  const disableLoading = () => setLoading(false);

  useEffect(() => {
    const token = readToken();
    if (token) {
      restoreSession(token)
        .catch((e) => {
          deleteToken();
        })
        .finally(disableLoading);
    } else {
      disableLoading();
      sessionChecked(true);
    }
  }, []);

  return { loading };
};

export default useSession;
