import { MainContainer } from "@common/MainContainer";
import { Box, ListingCompactModular } from "@thrivecoin/ui-components";
import { textLinkify } from "@utils/text";
import { useStoreState } from "easy-peasy";
import { useContext } from "react";
import styled from "styled-components";
import THeader from "../Season/BodySeason/WaysToContribute/THeader";
import { item } from "./dummy";
import { ListingEditorContext } from "./ListingEditorContext";

const RESPONSE_DELAY = 2000;

const delayedresponse = (data, time) => () => {
  return new Promise((resolve) => setTimeout(() => resolve({ activity: data }), time));
};

const BLANK_ACTION = () => {};

const PreviewListing = () => {
  const { listing } = useContext(ListingEditorContext);
  const { isMobile, isDesktop, isMiniTablet, isTablet } = useStoreState((state) => state.media);

  const getSnapshotDetails = delayedresponse(listing, RESPONSE_DELAY);
  const getActivityDetails = getSnapshotDetails;

  return (
    <MainContainer>
      <Wrapper>
        <Table>
          <THeader
            onSort={BLANK_ACTION}
            isMobile={isMobile}
            isDesktop={isDesktop}
            isTablet={isTablet}
            isMiniTablet={isMiniTablet}
            isSeason={true}
          />
          <StyledBody>
            <ListingCompactModular
              isAuthenticated
              reward={+item.reward_amount}
              canCompleteMultipleTimes={item.unlimited_contributions_per_user}
              type={item.contribution_app}
              manuallyVerification={item.verification_method === "manual"}
              custom_url={item.custom_url}
              completedCount={0}
              manuallyComplete={BLANK_ACTION}
              readMore={BLANK_ACTION}
              connectSocialMedias={BLANK_ACTION}
              getSnapshotDetails={getSnapshotDetails}
              getActivityDetails={getActivityDetails}
              onShare={BLANK_ACTION}
              {...item}
            />
          </StyledBody>
          <tbody>
            <ListingCompactModular
              isAuthenticated
              reward={+listing.reward_amount}
              canCompleteMultipleTimes={listing.unlimited_contributions_per_user}
              type={listing.contribution_app}
              manuallyVerification={listing.verification_method === "manual"}
              custom_url={listing.custom_url}
              completedCount={0}
              socialProfileConnected
              manuallyComplete={BLANK_ACTION}
              readMore={BLANK_ACTION}
              connectSocialMedias={BLANK_ACTION}
              getSnapshotDetails={getSnapshotDetails}
              getActivityDetails={getActivityDetails}
              onShare={() => {}}
              {...listing}
            />
          </tbody>
          <StyledBody>
            <ListingCompactModular
              isAuthenticated
              reward={+item.reward_amount}
              canCompleteMultipleTimes={item.unlimited_contributions_per_user}
              type={item.contribution_app}
              manuallyVerification={item.verification_method === "manual"}
              custom_url={item.custom_url}
              completedCount={0}
              manuallyComplete={BLANK_ACTION}
              readMore={BLANK_ACTION}
              connectSocialMedias={BLANK_ACTION}
              getSnapshotDetails={getSnapshotDetails}
              getActivityDetails={getActivityDetails}
              description={textLinkify(item.description)}
              onShare={() => {}}
              {...item}
            />
          </StyledBody>
        </Table>
      </Wrapper>
    </MainContainer>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .activity-btn{
    display: none;
  }
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  tbody:before {
    content: ".";
    display: block;
    line-height: 25px;
    text-indent: -99999px;
  }
`;

const StyledBody = styled.tbody`
  filter: blur(4px);

  & > * {
    pointer-events: none;
  }
`;

export default PreviewListing;
