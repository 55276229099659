import { Box, Button, IconSvg, Input, Modal, Text } from "@thrivecoin/ui-components";
import { isEmail } from "@utils/validations";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useRef, useState } from "react";
import styled from "styled-components";

const CHAR_CODES = [13, 32, 44]; //ENTER, SPACE and COMMA (,)

const InviteAdministratorsModal = ({ hideModal }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const { community } = useStoreState((state) => state.community);
  const [email, setEmail] = useState("");
  const [enteredEmails, setEnteredEmails] = useState([]);
  const [validationText, setValidationText] = useState("");
  const { inviteAdminsRequest, getPendingAdministratorsRequest, getAdministratorsRequest } = useStoreActions(
    (actions) => actions.members
  );
  const inputRef = useRef(null);

  const addEmail = () => {
    if (email.length > 0) {
      const isEmailValid = isEmail(email);
      const currentEmails = enteredEmails;
      currentEmails.push(email);
      setEnteredEmails(currentEmails);
      setEmail("");
      if (!isEmailValid) {
        setValidationText("One or more of the email address you added is invalid");
      }
    }
  };

  const onKeyPress = (e) => {
    if (CHAR_CODES.includes(e.charCode)) {
      e.preventDefault();
      addEmail();
    }
  };

  const onChangeValue = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const onRemoveEmail = (e) => {
    const index = e.target.dataset.index;
    const currentEmails = [...enteredEmails];
    currentEmails.splice(index, 1);
    setEnteredEmails(currentEmails);
    const invalidEmails = currentEmails.find((item) => !isEmail(item));
    if (!invalidEmails) {
      setValidationText("");
    }
  };

  const onClick = () => {
    inviteAdminsRequest({ communityId: community.id, emails: enteredEmails })
      .then(() => {
        getPendingAdministratorsRequest({ communityId: community.id, keyword: "" });
        getAdministratorsRequest({ communityId: community.id, keyword: "" });
        hideModal();
      })
      .catch(({ response }) => {
        setValidationText(response.data.message);
      });
  };

  const renderEmails = () => {
    return (
      <EmailsConainer flex overflow="auto">
        {enteredEmails.map((item, index) => {
          const isEmailValid = isEmail(item);
          return (
            <Email
              key={index}
              spacing="mt-3"
              flex
              alignItems="center"
              invalid={!isEmailValid}
              color={isEmailValid ? "02Primary700" : "destructive500"}
            >
              <Text bold>{item}</Text>
              <XContainer pointer flex alignItems="center" onClick={onRemoveEmail} data-index={index}>
                <IconSvg icon="x" width="20px" />
              </XContainer>
            </Email>
          );
        })}
      </EmailsConainer>
    );
  };

  const footerContent = (
    <ButtonsWrapper direction={isMobile && "column"} flex>
      <Button
        size="lg"
        heightSize="56px"
        borderRadius="56px"
        semiBold
        onClick={hideModal}
        kind="outline"
        borderColor="secondary200"
        variant="secondary200"
        color="02Primary700"
        modal={true}
        width="100%"
      >
        Cancel
      </Button>
      <StyledButton
        disabled={enteredEmails.length < 1 || !!validationText}
        size="lg"
        heightSize="56px"
        semiBold
        kind="solid"
        borderRadius="56px"
        onClick={onClick}
        bgColor="hover"
        grow="1"
        modal={true}
        width="100%"
      >
        Invite Administrators
      </StyledButton>
    </ButtonsWrapper>
  );

  return (
    <Modal open onClose={hideModal} leaf={true} maxWidth="684px" footerContent={footerContent}>
      <Wrapper>
        <Text bold type="header" size="28px" lineHeight="36px">
          Invite Administrators
        </Text>
        <Text color="02Primary700" spacing="mb-6 mt-3">
          Your Admins can edit Community info, create Community Challenges, and approve or reject contributions. They
          cannot add or remove other Admins.
        </Text>
      </Wrapper>
      <InputContainer flex direction="column" error={validationText}>
        {renderEmails()}
        <StyledInput
          ref={inputRef}
          value={email}
          onChange={onChangeValue}
          placeholder="Enter email address(es)"
          onKeyPress={onKeyPress}
          multipleEmails={enteredEmails.length > 0}
          onBlur={addEmail}
          modal
        />
      </InputContainer>
      {!!validationText && (
        <Box flex alignItems="center" gap="8px" color="destructive500" spacing="mt-2 ml-4">
          <IconSvg width="16px" icon="alert-triangle" />
          <Text>{validationText}</Text>
        </Box>
      )}
    </Modal>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;

const ButtonsWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  gap: 1.5rem;
`;

const StyledInput = styled(Input)`
  & input {
    padding-left: ${({ multipleEmails }) => (multipleEmails ? "24px" : "16px")};
  }
`;

const InputContainer = styled(Box)`
  border: 1px solid #c3c4d4;
  overflow: hidden;
  border-radius: 12px;
  background-color: #fafafa;

  &:has(input:focus),
  &:has(input:hover) {
    border: 1px solid #636ce5;
  }
`;

const Email = styled(Box)`
  padding: 4px 4px 4px 8px;
  border: 1px solid ${({ invalid }) => (invalid ? "#c52d2d" : "#c3c4d4")};
  border-radius: 6px;
  margin-left: 10px;
  background-color: #fff;

  &:first-child {
    margin-left: 1rem;
  }
`;

const EmailsConainer = styled(Box)`
  overflow-x: auto;
`;

const XContainer = styled(Box)`
  & > svg {
    pointer-events: none;
  }
`;

const StyledButton = styled(Button)`
  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey300};
    border-color: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors["02Primary0"]};
  }
`;

export default InviteAdministratorsModal;
