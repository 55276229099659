import { IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const THeader = ({
  onSort,
  isMobile,
  isDesktop,
  sortType,
  sortIcon,
  hide_amounts,
  isSeason,
  isTablet,
  isDashboard,
  isCustomToken,
  isMiniTablet,
}) => {
  return (
    <THead onClick={onSort} display={isMobile && "none"}>
      <tr>
        <HeaderElement width="362px" textAlign="center">
          <IconText>
            <IconSvg width="15px" icon="globe-not-filled" color="01Primary700" spacing="mx-a" />
            {sortType === "network" && <IconSvg data-name="network" width="7.5px" icon={sortIcon} />}

            <HeaderText data-name="contribution" typography="body/small" color="01Primary700" $isSorted bold>
              Contribution
              {sortType === "contribution" && <IconSvg width="7.5px" icon={sortIcon} />}
            </HeaderText>
          </IconText>
        </HeaderElement>
        {!hide_amounts && (
          <HeaderElement width="198px">
            {" "}
            <HeaderText data-name="reward" typography="body/small" color="01Primary700" $isSorted bold>
              {isCustomToken && isSeason ? "End of Season Prize" : "Reward"}
              {sortType === "reward" && <IconSvg width="7.5px" icon={sortIcon} />}
            </HeaderText>
          </HeaderElement>
        )}
        <HeaderElement
          display={(!isDesktop || !isDashboard) && "none"}
          minWidth={isTablet ? "180px" : "100px"}
          width="172px"
        >
          <HeaderText typography="body/small" color="01Primary700" bold>
            Community{" "}
          </HeaderText>
        </HeaderElement>
        <HeaderElement
          display={(isMiniTablet || isDashboard) && "none"}
          minWidth={isTablet ? "180px" : "145px"}
          width={isDesktop && "172px"}
        >
          <HeaderText typography="body/small" color="01Primary700" bold>
            Completions{" "}
          </HeaderText>
        </HeaderElement>
        <HeaderElement
          display={isMobile || (((isTablet && !isDashboard) || isMiniTablet) && "none")}
          width={isDesktop && "166px"}
        >
          <HeaderText typography="body/small" color="01Primary700" bold>
            Can Be Completed
          </HeaderText>
        </HeaderElement>
        <HeaderElement display={isTablet && "none"}>
          <HeaderText data-name="verification_method" typography="body/small" color="01Primary700" $isSorted bold>
            Completion Check {sortType === "verification_method" && <IconSvg width="7.5px" icon={sortIcon} />}
          </HeaderText>
        </HeaderElement>
        <HeaderElement></HeaderElement>
      </tr>
    </THead>
  );
};

const THead = styled.thead`
  display: ${({ display }) => display && display};
`;

const HeaderElement = styled.th`
  text-align: left;
  min-width: ${({ minWidth }) => minWidth && minWidth};
  width: ${({ width }) => width && width};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  display: ${({ display }) => display && display};
`;

const IconText = styled.div`
  display: grid;
  grid-template-columns: 64px auto;
  text-align: left;
  svg {
    margin: 0 auto 0 0;
  }
`;

const HeaderText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    ${({ $isSorted }) => $isSorted && "text-decoration: underline; cursor: pointer;"};
  }
`;

export default THeader;
