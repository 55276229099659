import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("feature_switches");
const attributes = () => ({
  featureSwitches: [],
});

const actions = {
  getFeatureSwitches: thunk(({ setFeatureSwitches }) => {
    return requester.get().then(({ feature_switches }) => {
      setFeatureSwitches(feature_switches);
    });
  }),
  setFeatureSwitches: action((state, feature_switches) => {
    state.featureSwitches = feature_switches;
  }),
};

const featureSwitches = {
  ...attributes(),
  ...actions,
};

export default featureSwitches;
