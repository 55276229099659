import { Box, Button, Grid, Input, Text, TextArea } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { usePendingContributionContext } from "./PendingContributionContext";

const ReasonsText = {
  uncompleted: "Contribution was submitted, but is not considered complete",
  unable_to_verify_completion: "Unable to verify contribution was completed",
  inappropriate: "Inappropriate contribution was submitted",
  due_to_other_contributions:
    "Contribution was completed, but is rejected in favor of other contributions (Not recommended)",
};

const RadioButton = styled(Input)`
  ${({ theme }) => `

      input[type='radio'] {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        outline: none;
        padding: 2px;
        cursor: pointer;
        margin-top: 5px;
        border-color: ${theme.colors["02PrimaryGrey300"]};
        background: ${theme.colors["02Primary0"]};
        position: relative;
      }

      input[type='radio']:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
      }

      input[type='radio']:checked {
        border: 1px solid ${theme.colors["error"]};
        background: #FFFFFF;
      }

      input[type='radio']:checked:before {
        background-color: ${theme.colors["error"]};
      }
  `}
`;

const reasons = Object.keys(ReasonsText);

const PLACEHOLDER = "Changes needed for contribution to be approved (Optional)";

const RejectStep = ({ isMobile }) => {
  const { selected, setSelected, reviewer_comments, setRejectionNote } = usePendingContributionContext();

  const selectReason = (event) => {
    const reason = event.currentTarget.dataset.value;
    setSelected(reason);
  };

  const changeRejectionNote = (event) => {
    setRejectionNote(event.target.value);
  };

  const elements = reasons.map((reason) => (
    <OptionBox key={reason} selected={selected == reason} data-value={reason} onClick={selectReason}>
      <RadioButton type="radio" checked={selected == reason} />
      {ReasonsText[reason]}
    </OptionBox>
  ));

  return (
    <Box flex direction="column" spacing="mb-5">
      <StyledText typography="heading/medium" spacing="mb-4" color="02Primary500" bold>
        Select reason for rejecting contribution
      </StyledText>
      <StyledText typography="body/medium" spacing="mx-a" color="02Primary500" spacing="mb-6">
        <b>Please note:</b> Contributors can re-submit contributions until they're accepted.
      </StyledText>

      <Grid spacing={isMobile ? "mb-4" : "mb-6"} gap={isMobile ? "16px" : "1.5rem"}>
        {elements}
      </Grid>

      <TextArea
        value={reviewer_comments}
        onChange={changeRejectionNote}
        name="rejection-note"
        spacing={isMobile ? "mb-4" : "mb-6"}
        borderColor="02PrimaryGrey300"
        bgColor="02PrimaryGrey50"
        color="02Primary500"
        placeholder={PLACEHOLDER}
        rows="6"
        modal
      />

      {isMobile && <Box bgColor="02PrimaryGrey300" spacing="mt-5" height="1px" width="100%" />}
    </Box>
  );
};

const OptionBox = styled(Box)`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  color: ${({ theme }) => theme.colors["02Primary500"]};
  background: ${({ theme }) => theme.colors["02PrimaryGrey100"]};
  border-radius: 12px;
  border: 1px solid transparent;
  gap: 12px;

  ${({ selected, theme }) =>
    selected &&
    `
    border: 1px solid ${theme.colors["error"]};
  `}
`;

const StyledButton = styled(Box)`
  flex-grow: 1;
  display: flex;

  & button {
    &:disabled {
      background: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
      border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    }
  }
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const Footer = ({ isMobile, id, contribution_claim_id, hideModal }) => {
  const { onClickReject, loading, disabled, reviewer_comments } = usePendingContributionContext();
  const handleRejectClick = () => {
    onClickReject(id, contribution_claim_id, reviewer_comments, hideModal);
  };
  return (
    <Box flex direction={isMobile && "column"} gap="16px" justifyContent="space-between">
      <Button
        borderRadius="50px"
        bold
        kind="outline"
        variant="white"
        onClick={hideModal}
        grow="1"
        spacing=" py-4"
        modal={true}
      >
        Cancel
      </Button>
      <StyledButton>
        <Button
          spacing="py-4"
          borderRadius="50px"
          semiBold
          grow={1}
          variant="red"
          disabled={disabled}
          onClick={handleRejectClick}
          loading={loading}
          modal={true}
        >
          Reject
        </Button>
      </StyledButton>
    </Box>
  );
};

RejectStep.Footer = Footer;
export default RejectStep;
