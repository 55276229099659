import { Box, Button, Input, Modal, Text } from "@thrivecoin/ui-components";
import { isEmail } from "@utils/validations";
import { useStoreActions } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";

const ConnectEmailModal = ({ hideModal }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { emailVerificationCode, resendVerificationCode, connectSocialProfile } = useStoreActions(
    (action) => action.authentication
  );

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const reSendEmail = () => resendVerificationCode();

  const onCompleted = (...args) => connectSocialProfile(...args).then(hideModal);

  const onClick = () => {
    setLoading(true);
    emailVerificationCode(email)
      .then(() => {
        hideModal();
        showModal({
          modalName: "PinCodeVerificationModal",
          email,
          socialProfile: true,
          showReSend: true,
          onCompleted,
          reSendEmail,
        });
      })
      .catch(({ response }) => setError(response.data?.data?.errors?.email[0] || response.data.message))
      .finally(() => setLoading(false));
  };

  const footerContent = (
    <Button
      size="lg"
      borderRadius="40px"
      bold
      loading={loading}
      disabled={!isEmail(email) || loading}
      width="100%"
      onClick={onClick}
      disabledColor="02Primary0"
      disabledBackground="02PrimaryGrey300"
      disabledBorderColor="02PrimaryGrey300"
      modal={true}
    >
      Verify Email
    </Button>
  );
  return (
    <Modal open leaf onClose={hideModal} maxWidth="684px" footerContent={footerContent}>
      <Text typography="heading/large" spacing="mx-a my-5">
        Contributions Via Email
      </Text>
      <StyledText>
        Type the email address associated with your contributions. Your email will ONLY be used for
        contribution-verification purposes. It will NEVER be shared, published, or used for marketing.
      </StyledText>
      <BottomWrap>
        <StyledInput placeholder="Email" value={email} onChange={onChange} validationText={error} modal/>
      </BottomWrap>
    </Modal>
  );
};

const StyledText = styled(Text)`
  margin: 0 18px;
  text-align: center;
`;

const BottomWrap = styled(Box)`
  margin: 0 50px 0;
`;

const StyledInput = styled(Input)`
  margin: 2rem 0 0 0;
`;

export default ConnectEmailModal;
