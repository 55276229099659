import { Box, Button, Input, Text, TextArea } from "@thrivecoin/ui-components";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import { statusMap } from "./data";

const REASON_KEY = {
  banned: "banned_reason",
  flagged: "flagged_reason",
  restricted: "restricted_reason",
};

const ReasonStep = ({ type, setAditionalNotes, profileData }) => {
  const banStatus = statusMap[type] || "banned";
  const reasonKey = REASON_KEY[banStatus];

  const handleChange = (event) => {
    setAditionalNotes(event.target.value);
  };
  return (
    <StyledBox flex direction="column">
      <Box>
        <Text typography="body/medium" spacing="mb-3 mr-a">
          You have {banStatus} this user in the past for the following reason:
        </Text>
        <StyledInput bgColor="02PrimaryGrey50" color="02Primary500" value={profileData[reasonKey]} readOnly />
      </Box>
      <Box height="1px" bgColor="02PrimaryGrey150" spacing="my-5" />

      <Text typography="heading/small" spacing="mb-5 mr-a" bold>
        Reason to {capitalizeFirstLetter(type)} User
      </Text>

      <StyledTextArea
        bgColor="02PrimaryGrey50"
        color="02Primary500"
        placeholder="Additional Notes - Describe here in more detail why you decided to unban this user."
        rows="3"
        onChange={handleChange}
        modal
      ></StyledTextArea>
    </StyledBox>
  );
};

const Footer = ({ closeModal, goToStep }) => {
  const goNext = () => goToStep(STEP_INDEXES.NotifyStep);
  return (
    <Box flex gap="16px">
      <Button variant="white" kind="outline" borderRadius="50px" width="100%" onClick={closeModal} modal>
        Cancel
      </Button>
      <Button borderRadius="50px" width="100%" onClick={goNext} modal>
        Next Step{" "}
      </Button>
    </Box>
  );
};

const StyledBox = styled(Box)`
  text-align: left;
`;

const StyledInput = styled(Input)`
  input {
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    &:hover {
      border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
      cursor: default;
    }
  }
`;

export const StyledTextArea = styled(TextArea)`
  textarea {
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  }
`;
ReasonStep.Footer = Footer;

export default ReasonStep;
