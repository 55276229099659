export const data = {
  isAdmin: {
    title: "Personalized Notification",
    description:
      "  Let the user know the good news that they are unbanned and can access and contribute to Communities again!",
    btn: "Unban User",
  },
  isAdminOfCommunity: {
    title: "Note to the SuperAdmin",
    description:
      "Let the SuperAdmin know in more detail why you feel that this user should be unbanned so that the SuperAdmin can make the right decision.",
    btn: "Request to UnBan User",
  },
};

export const statusMap = {
  unban: "banned",
  unflag: "flagged",
  unrestrict: "restricted"
};
