import { Box, breakpoints, IconSvg, Text, TokenReward } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { isSeasonCompleted } from "../../common";
import useColors from "../../useColors";
import ToggleButton from "./ToggleButton";

import CONTRIBUTIONS from "@assets/contributions.svg";
import RAFFLES from "@assets/raffles.svg";

const displayNone = ({ hide }) => hide && "display: none;";

const SeasonStats = ({ season, seasonDetails }) => {
  const [expanded, setExpanded] = useState(false);
  const { isTablet, isMobile, isDesktop } = useStoreState((state) => state.media);
  const raffleSystem = season?.reward_system === "raffle";
  const seasonCompleted = isSeasonCompleted(season.status);

  const toggleExpanded = () => setExpanded((prev) => !prev);

  const showSecondSeperator = (isDesktop && raffleSystem) || isTablet;
  const { theme } = useColors(season);

  return (
    <Wrapper bgColor="headerGradient" borderRadius="8px 8px 0 0" color="headerText" onClick={toggleExpanded}>
      <EarningsBox flex $raffleSystem={raffleSystem}>
        <Box flex direction="column" justifyContent="center" gap="16px">
          <EarningsText bold typography="body/small" lineHeight="24px">
            My Earnings this Season
          </EarningsText>
          <TokenReward color="headerText" token={season?.rewards_token} amount={seasonDetails?.rewards_earned || 0} />
        </Box>
        {isMobile && (
          <Box spacing="ml-a">
            <ToggleButton expanded={expanded} setExpanded={setExpanded} />
          </Box>
        )}
      </EarningsBox>
      {(expanded || !isMobile) && (
        <>
          <Seperator bgColor="headerSurfaces" />
          <Box flex direction="column" justifyContent="center" gap="16px">
            <StyledText bold typography="body/small">
              My Contributions
            </StyledText>
            <Box flex alignItems="center" gap="14px">
              <StatImageWrapper bgColor={theme.accentColor}>
                <StatImage src={CONTRIBUTIONS} />
              </StatImageWrapper>
              <StatCount>{(+seasonDetails?.contributions_made || 0).toLocaleString()}</StatCount>
            </Box>
          </Box>

          {showSecondSeperator && <Seperator $isSecond bgColor="headerSurfaces" />}
          <Box flex direction="column" justifyContent="center" gap="16px">
            {raffleSystem && (
              <>
                <StyledText bold typography="body/small">
                  My Raffle entries
                </StyledText>
                <Box flex alignItems="center" gap="14px">
                  <StatImageWrapper bgColor={theme.accentColor}>
                    <StatImage src={RAFFLES} />
                  </StatImageWrapper>
                  <StatCount>{(+seasonDetails?.raffle_entries || 0).toLocaleString()}</StatCount>
                </Box>
              </>
            )}
          </Box>
          {!seasonCompleted && (
            <DatesWrapper>
              <DateBox bgColor={theme.accentColor} spacing="mb-3" md-spacing="mt-5" sm-spacing="mt-5">
                <IconSvg icon="pacman" width="18px" color="02Primary0" />
                <Text bold typography="body/small" color="02Primary0">
                  Season ends: {formatDate(season.end_date)}
                </Text>
              </DateBox>
              {raffleSystem && (
                <DateBox bgColor="headerSurface">
                  <IconSvg icon="pacman" width="18px" color="02Primary0" />
                  <Text bold typography="body/small" color="02Primary0">
                    Raffle date: {formatDate(season.raffle_draw_date)}
                  </Text>
                </DateBox>
              )}
            </DatesWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

const DatesWrapper = styled(Box)`
  @media ${breakpoints.miniTablet} {
    flex-grow: 1;
  }
`;

const Wrapper = styled(Box)`
  display: inline-flex;
  gap: 22px;
  padding: 14px 18px 18px 25px;

  @media ${breakpoints.desktop} {
    ${DatesWrapper} {
      margin-left: auto;
    }
  }

  @media ${breakpoints.tablet} {
    padding: 16px 24px 32px 24px;
    gap: 0;
    display: flex;
    flex-direction: column;
  }

  @media ${breakpoints.miniTablet} {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    & > div:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
      justify-content: flex-end;
    }

    & div:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }

    & div:nth-child(3) {
      grid-area: 1 / 3 / 2 / 4;
    }

    & div:nth-child(4) {
      grid-area: 2 / 1 / 2 / 6;
    }

    & div:nth-child(5) {
      grid-area: 2 / 1 / 2 / 6;
    }

    & div:nth-child(6) {
      grid-area: 3 / 1 / 3 / 6;
    }
  }
  ${breakpoints.mobile} {
    display: grid;
    grid-template-columns: repeat(2, 1 fr);

    & div:nth-child(1) {
      grid-area: 1 / 1 / 2 / 3;
    }

    & div:nth-child(2) {
      grid-area: 2 / 1 / 3 / 3;
    }

    & div:nth-child(3) {
      grid-area: 3 / 1 / 4 / 2;
    }

    & div:nth-child(4) {
      grid-area: 3 / 2 / 4 / 3;
    }

    & div:nth-child(5) {
      grid-area: 4 / 1 / 5 / 3;
    }
  }

  @media ${breakpoints.mobile} {
    padding: 20px 20px 24px;
    flex-direction: column;
  }
`;

const StatImageWrapper = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatImage = styled.img`
  width: 22px;
  height: 22px;
`;

const StatCount = styled(Text)`
  font-size: 30px;
  line-height: 34px;
  font-weight: 600;
`;

const DateBox = styled(Box)`
  padding: 6.5px 20px 6.5px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  @media ${breakpoints.tablet} {
    margin-top: 0 !important;
  }
  @media ${breakpoints.tablet}, ${breakpoints.mobile} {
    justify-content: center;
  }
`;

const Seperator = styled(Box)`
  width: 1px;

  ${displayNone}
  @media ${breakpoints.tablet} {
    margin: 24px 0;
    width: unset;
    height: 1px;
  }
  @media ${breakpoints.miniTablet} {
    width: ${({ $isSecond }) => ($isSecond ? "100%" : "1px")};
    height: ${({ $isSecond }) => ($isSecond ? "1px" : "unset")};
    margin: ${({ $isSecond }) => ($isSecond ? "24px 0" : "0 auto")};
  }
  @media ${breakpoints.mobile} {
    width: unset !important;
    height: 1px;
    margin: 15px 0;
  }

  @media ${breakpoints.mobile} {
    margin: 20px 0;
  }
`;

const EarningsBox = styled(Box)`
  @media ${breakpoints.desktop} {
    ${({ $raffleSystem }) => !$raffleSystem && "margin-right: 64px;"}
  }

  @media ${breakpoints.miniTablet} {
    ${({ $raffleSystem }) => !$raffleSystem && "width: 230px;"}
  }
  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  line-height: 24px;

  @media ${breakpoints.miniTablet} {
    margin-right: 10px;
  }
`;

const EarningsText = styled(Box)`
  @media ${breakpoints.desktop} {
    white-space: nowrap;
  }
`;

export default SeasonStats;
