import BuildingsImg from "@assets/profile/buildings.svg";
import GoBack from "@common/GoBack";
import { Box, breakpoints } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { MainContainer } from "../../common/MainContainer";
import EditPart from "./EditPart";
import TopPart from "./TopPart";

const Cover = ({ isPublicProfile, user }) => {
  const { publicUser } = useStoreState((state) => state.authentication);
  const [edit, showEdit] = useState(false);
  const profileData = isPublicProfile ? publicUser : user;
  const canEdit = !isPublicProfile;

  return (
    <>
      <CoverWrapper>
        <Buildings src={BuildingsImg} />
        <MainContainer>
          <GoBack isAbsolute={false} />
        </MainContainer>
      </CoverWrapper>
      <Wrapper>
        {edit ? (
          <EditPart showEdit={showEdit} data={user} />
        ) : (
          <>
            <TopPart isPublicProfile={isPublicProfile} showEdit={canEdit && showEdit} profileData={profileData} />
          </>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  margin-top: -250px;
  @media ${breakpoints.tablet} {
    margin: -205px auto 0 auto;
    padding: 0 24px;
    max-width: 553px;
  }
  @media ${breakpoints.mobile} {
    margin-top: -214px;
    padding: 0 16px;
  }
`;

const Buildings = styled.img`
  position: absolute;
  bottom: -70px;
  left: 0;
  right: 0;
`;

const CoverWrapper = styled(Box)`
  overflow: hidden;
  background: ${({ theme }) => theme.colors["greenGradient"]};
  position: relative;
  margin: 0;
  display: flex;
  min-height: 249px;
  padding-top: 24px;
  @media ${breakpoints.mobile} {
    padding-top: 16px;
  }
  .go-back {
    @media ${breakpoints.tablet} {
      position: absolute;
      left: 24px;
      top: 0;
    }
  }
  @media ${breakpoints.tablet} {
    padding-top: 25px;
    min-height: 240px;
  }
  & > div {
    margin-top: 0;
    @media ${breakpoints.tablet} {
      & > div {
        position: absolute;
        left: 24px;
        top: 0;
      }
    }
  }
`;
export default Cover;
