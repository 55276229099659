import { Box, Button, DropDown, Text, TextArea } from "@thrivecoin/ui-components";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import styled from "styled-components";
import { REASONS } from "./constants";

const ReasonStep = ({ type, description, note, additional_notes, reason }) => {
  return (
    <StyledBox flex direction="column">
      <Text typography="body/medium" spacing="mb-3 mr-a">
        {description}
      </Text>

      {note && (
        <TextNote typography="body/medium">
          <Text bold inline>
            Note:{" "}
          </Text>
          {note}
        </TextNote>
      )}
      <Box height="1px" bgColor="02PrimaryGrey150" spacing="my-5" />

      <Text typography="heading/small" spacing="mb-5 mr-a" bold>
        Reason to {capitalizeFirstLetter(type)} this User
      </Text>

      <DropDown
        value={reason}
        name="reason"
        color="02Primary200"
        bgColor="02PrimaryGrey50"
        optionsBgColor="02PrimaryGrey50"
        label="label"
        valueKey="id"
        options={REASONS}
        placeholder="Select an option"
        required
      />

      <StyledTextArea
        value={additional_notes}
        spacing="mt-5"
        bgColor="02PrimaryGrey50"
        color="02Primary500"
        name="additional_notes"
        placeholder="Additional Notes - Describe here in more detail why"
        rows="3"
        modal
      ></StyledTextArea>
    </StyledBox>
  );
};

const Footer = ({ closeModal }) => {
  return (
    <Box flex gap="16px">
      <Button variant="white" kind="outline" borderRadius="50px" width="100%" onClick={closeModal} modal>
        Cancel
      </Button>
      <Button borderRadius="50px" width="100%" type="submit" modal>
        Next Step
      </Button>
    </Box>
  );
};

const StyledBox = styled(Box)`
  text-align: left;
`;

export const StyledTextArea = styled(TextArea)`
  textarea {
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  }
`;

const TextNote = styled(Text)`
  max-width: 524px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors["03Secondary500"]};
`;

ReasonStep.Footer = Footer;

export default ReasonStep;
