import { Box, Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { StyledTextArea } from "./ReasonStep";

const WORD_MAP = {
  ban: "banned",
  flag: "flagged",
  restrict: "restricted",
};

const NotifyStep = ({ type }) => {
  return (
    <StyledBox>
      <Text typography="heading/small" spacing="mb-4" bold>
        Personalized Notification
      </Text>
      <Text typography="body/medium" spacing="mb-5">
        To make communication with the user less automated, we suggest you explain here to the user why he or she got{" "}
        {WORD_MAP[type]} in his or her specific case. In case of a bot, never mind!
      </Text>

      <StyledTextArea
        bgColor="02PrimaryGrey50"
        color="02Primary500"
        placeholder="Type personal Message here..."
        rows="3"
        name="message"
        modal
      ></StyledTextArea>
    </StyledBox>
  );
};

const Footer = ({ goBack, type, icon = "check", loading }) => {
  return (
    <Box flex gap="16px">
      <Button variant="white" kind="outline" borderRadius="50px" width="100%" onClick={goBack} modal loading={loading}>
        Back
      </Button>
      <StyledButton
        bgColor="error"
        borderRadius="50px"
        width="100%"
        leftIcon={icon}
        submit
        modal
        type="submit"
        loading={loading}
      >
        {type} User
      </StyledButton>
    </Box>
  );
};

const StyledBox = styled(Box)`
  text-align: left;
`;

const StyledButton = styled(Button)`
  text-transform: capitalize;
`;

NotifyStep.Footer = Footer;

export default NotifyStep;
